<template>
    <div class="home-page">
        <section class="hero is-medium is-primary is-bold">
            <div class="hero-body">
                <div class="container has-text-centered">
                    <figure class="image is-inline-block">
                        <img alt="Grandmasters" src="@/assets/white_knight640.png">
                    </figure>
                    <h1 class="title is-spaced">
                        Grandmaster's Ascent : Chess evolved
                    </h1>
                    <h2 class="subtitle">
                        Get a feel for the Game with this pre Alpha Demo
                    </h2>
                    <a href="https://drive.google.com/drive/folders/1Eob7g3nm36n2is1mRou90D0Iqf0uMytN?usp=sharing" class="button is-primary is-link is-danger is-large is-rounded" download>Download</a>

                    <!-- <div class="container">
                        <h2 class="title">Countdown to Release</h2>
                        <div id="countdown" class="has-text-centered"></div>
                        <div class="countdown-timer has-text-centered">
                        {{ days }} days {{ hours }} hours {{ minutes }} minutes {{ seconds }} seconds
                        </div>
                    </div>
                    <button class="button is-dark has-text-centered" @click="goToDashboard">
                        Get Started
                    </button>

                    <div v-if="user">
                        <button @click="signOut">Sign Out</button>
                    </div>
                    <div v-else>
                        <button @click="signInWithGoogle()">Sign In with Google</button>
                    </div> -->
                </div>
            </div>
        </section>

        <section class="section">
        <div class="container">
            <h2 class="title has-text-centered">Features</h2>
            <div class="columns is-multiline">
            <div class="column is-one-third">
                <div class="card">
                <div class="card-image">
                    <figure class="image is-4by3">
                    <img src="@/assets/white_pawn640.png" alt="Feature 1">
                    </figure>
                </div>
                <div class="card-content">
                    <h3 class="title is-4">Engaging Storyline</h3>
                    <p>Play against Stockfish in a completely new Chess experience.</p>
                </div>
                </div>
            </div>
            <div class="column is-one-third">
                <div class="card">
                <div class="card-image">
                    <figure class="image is-4by3">
                    <img src="@/assets/white_queen640.png" alt="Feature 2">
                    </figure>
                </div>
                <div class="card-content">
                    <h3 class="title is-4">Strategic Gameplay</h3>
                    <p>Master a combination of cards and pieces to outsmart your opponents.</p>
                </div>
                </div>
            </div>
            <!-- <div class="column is-one-third">
                <div class="card">
                <div class="card-image">
                    <figure class="image is-4by3">
                    <img src="@/assets/white_rook640.png" alt="Feature 3">
                    </figure>
                </div>
                <div class="card-content">
                    <h3 class="title is-4">Stunning Visuals</h3>
                    <p>Enjoy breathtaking graphics and visual effects as you play.</p>
                </div>
                </div>
            </div> -->
            </div>
        </div>
        </section>

        <section class="section">
        <div class="container">
            <h2 class="title has-text-centered">Game Trailer</h2>
            <div class="video-container">
            <iframe width="560" height="315" src="https://www.youtube.com/embed/KrXMkz4kxm0" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
            </div>
        </div>
        </section>

        <!-- <section class="section">
        <div class="container">
            <h2 class="title has-text-centered">Get Ready for the Launch</h2>
            <div class="has-text-centered">
            <a href="https://store.steampowered.com/app/your_app_id" target="_blank" rel="noopener" class="button is-primary">Add to Steam Wishlist</a>
            <a href="https://your_preorder_link.com" target="_blank" rel="noopener" class="button is-info">Pre-order Now</a>
            </div>
        </div>
        </section> -->

        <!-- <section class="section">
        <div class="container">
            <h2 class="title has-text-centered">What Players Say</h2>
            <div class="columns is-multiline">
                <div class="column is-one-third">
                    <blockquote>
                    "This game has an amazing story and engaging gameplay. I can't wait for the full release!"
                    <cite>- Player 1</cite>
                    </blockquote>
                </div>
                <div class="column is-one-third">
                    <blockquote>
                    "Cool Game!"
                    <cite>- Player 2</cite>
                    </blockquote>
                </div>
            </div>
        </div>
        </section> -->

        <!-- <section class="section">
        <div class="container">
            <h2 class="title has-text-centered">Game Screenshots</h2>
            <div class="columns is-multiline">
                <div class="column is-one-third">
                    <figure class="image">
                    <img src="@/assets/game_screenshot1.png" alt="Screenshot 1">
                    </figure>
                </div>
                <div class="column is-one-third">
                    <figure class="image">
                    <img src="@/assets/game_screenshot1.png" alt="Screenshot 1">
                    </figure>
                </div>
                <div class="column is-one-third">
                    <figure class="image">
                    <img src="@/assets/game_screenshot1.png" alt="Screenshot 1">
                    </figure>
                </div>
            </div>
        </div>
        </section> -->

        <!-- <section class="section">
        <div class="container">
            <h2 class="title has-text-centered">Latest News</h2>
            <div class="columns is-multiline">
                <div class="column is-one-third">
                    <article>
                    <figure class="image">
                        <img src="@/assets/news1.png" alt="News Thumbnail 1">
                    </figure>
                    <h3 class="title is-4">New UI</h3>
                    <p>This week we received the Graphics for the new UI</p>
                    <a href="/news/news1">Read More</a>
                    </article>
                </div>
            </div>
        </div>
        </section> -->

        <!--<section class="section">
        <div class="container">
            <h2 class="title has-text-centered">Awards and Recognition</h2>
            <div class="columns is-multiline">
            <div class="column is-one-third">
                <figure class="image">
                <img src="./assets/logo.png" alt="Award 1">
                </figure>
                <h3 class="title is-4">Award Title 1</h3>
                <p>Award description...</p>
            </div>
            !-- Add more awards as needed --
            </div>
        </div>
        </section>-->

        <!-- <section class="section">
        <div class="container">
            <h2 class="title has-text-centered">Frequently Asked Questions</h2>
            <div class="content">
            <p><strong>Question 1:</strong> Answer to the first question...</p>
            <p><strong>Question 2:</strong> Answer to the second question...</p>
            </div>
        </div>
        </section> -->

        <!-- <section class="section">
        <div class="container">
            <h2 class="title has-text-centered">Join Our Community</h2>
            <p class="content">Connect with fellow players and our development team on our <a href="https://discord.gg/your_invite_link" target="_blank" rel="noopener">Discord server</a> or follow our <a href="https://www.reddit.com/r/your_subreddit/" target="_blank" rel="noopener">subreddit</a>. You can also join the conversation on our official <a href="https://www.facebook.com/groups/your_group/" target="_blank" rel="noopener">Facebook group</a>.</p>
        </div>
        </section> -->

        <footer class="footer">
        <!-- <div class="content has-text-centered">
            <h2 class="title has-text-centered">Follow Us</h2>
            <div class="social-icons has-text-centered">
            <a href="https://twitter.com/your_handle" target="_blank" rel="noopener">
                <font-awesome-icon :icon="['fab', 'twitter']" />
            </a>
            <a href="https://facebook.com/your_page" target="_blank" rel="noopener">
                <font-awesome-icon :icon="['fab', 'facebook']" />
            </a>
            <a href="https://instagram.com/your_handle" target="_blank" rel="noopener">
                <font-awesome-icon :icon="['fab', 'instagram']" />
            </a>
            <router-link to="/game-studio">About Studio</router-link>
            </div>
        </div> -->

        <div class="content has-text-centered">
            <p>
            Built with
            <a href="https://vuejs.org" target="_blank" rel="noopener">Vue.js</a> and
            <a href="https://firebase.google.com" target="_blank" rel="noopener">Firebase</a>
            </p>
        </div>
        </footer>
    </div>
    </template>

    <script>
    import { provider, signOutUser, authStateChanged, auth, signInWithPopup } from '@/firebase';
    const isAuthenticated = false

    export default {
        name: 'HomePage',
        components: {

        },
        created() {
        authStateChanged((user) => {
            console.log(`You are ${user ? 'logged in' : 'logged out'}`);
            this.user = user;
        });
        },
        computed: {
        username() {
            return this.$route.params.username
        },
        },
        methods: {
        async signInWithGoogle() {
            try {
            const result = await signInWithPopup(auth, provider);
            // const result2 = await signInWithRedirect(provider);
            console.log(result)
            } catch (error) {
            console.log('Error during Google sign-in:', error);
            }
        },
        async signOut () {
            try {
            await signOutUser();
            this.user = null;
            } catch (error) {
            console.error('Error during sign-out:', error);
            }
        },
        goToDashboard() {
            if (isAuthenticated) {
            this.$router.push('/')
            } else {
            this.$router.push('/login')
            }
        },
        },
        data() {
        return {
            days: 0,
            hours: 0,
            minutes: 0,
            seconds: 0,

            user: null,
        };
        },
        mounted() {
        // auth.onAuthStateChanged((user) => {
        //   if (user) {
        //     this.user = user;
        //   }
        // });
        const releaseDate = new Date('2023-12-01T00:00:00').getTime();
        const updateCountdown = () => {
            const now = new Date().getTime();
            const timeLeft = releaseDate - now;

            this.days = Math.floor(timeLeft / (1000 * 60 * 60 * 24));
            this.hours = Math.floor((timeLeft % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
            this.minutes = Math.floor((timeLeft % (1000 * 60 * 60)) / (1000 * 60));
            this.seconds = Math.floor((timeLeft % (1000 * 60)) / 1000);
        };

        updateCountdown();
        setInterval(updateCountdown, 1000);
        },
    };
    </script>

    <style>
    @import 'bulma/css/bulma.css';

    .gma-homepage {
        background-color: #fbfff7;
        min-height: 100vh;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    .hero {
        background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('@/assets/background-image.jpg');
        background-size: cover;
        background-position: center;
    }

    .countdown-timer {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        margin: 20px;
    }

    .video-container {
        position: relative;
        width: 100%;
        padding-bottom: 56.25%;
        height: 0;
        overflow: hidden;
    }

    .video-container iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    .newsletter-form {
        display: flex;
        justify-content: center;
        max-width: 400px;
        margin: 0 auto;
    }

    .field.has-addons.is-centered {
        justify-content: center;
        width: 100%;
    }

    .social-icons {
        font-size: 24px;
        display: flex;
        justify-content: center;
    }

    .social-icons a {
        color: inherit;
        margin: 0 10px;
        text-decoration: none;
    }

    .social-icons a:hover {
        color: #333;
    }

    </style>
