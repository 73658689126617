<template>
  <div id="app">
    <header>
      <!-- Insert your header components or content here -->
    </header>

    <nav>
      <!-- Insert your navigation components or content here -->
      <router-link to="/">Home</router-link>
      <router-link to="/game-studio">Game Studio</router-link>
    </nav>

    <main>
      <router-view></router-view>
    </main>

    <footer>
      <!-- Insert your footer components or content here -->
    </footer>
  </div>
</template>

<script>
export default {
  name: 'App',
};
</script>

<style>
/* Your global CSS styles go here */
</style>
