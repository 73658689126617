<template>
    <div class="home-page">
        <section class="hero is-medium is-primary is-bold">
        <div class="hero-body">
            <div class="container has-text-centered">
            <figure class="image is-inline-block">
                <img alt="Grandmasters" src="@/assets/ZugzwangLogo.png">
            </figure>
            <h1 class="title is-spaced">
                Zugzwang Studios
            </h1>
            <button class="button is-dark has-text-centered" @click="goToDashboard">
                Get Started
            </button>

            <!-- <div v-if="user">
                <button @click="signOut">Sign Out</button>
            </div>
            <div v-else>
                <button @click="signInWithGoogle()">Sign In with Google</button>
            </div> -->
        </div>
        </div>
        </section>

        <section class="section">
        <div class="container">
            <h2 class="title has-text-centered">Meet the Team</h2>
            <div class="columns is-multiline">
            <div class="column is-one-third has-text-centered">
                <figure class="image">
                <img src="@/assets/luis.jpg" alt="Luis Arnold">
                </figure>
                <h3 class="title is-4 has-text-centered">Luis Arnold</h3>
                <p class="has-text-centered">CEO, Game Designer, Developer</p>
            </div>
            <!-- Add more team members as needed -->
            </div>
        </div>
        </section>

        <!-- <section class="section">
        <div class="container">
            <h2 class="title has-text-centered">Latest News</h2>
            <div class="columns is-multiline">
            <div class="column is-one-third">
                <article>
                <figure class="image">
                    <img src="@/assets/news1.png" alt="News Thumbnail 1">
                </figure>
                <h3 class="title is-4">New UI</h3>
                <p>This week we received the Graphics for the new UI</p>
                <a href="/news/news1">Read More</a>
                </article>
            </div>
            </div>
        </div>
        </section> -->

        <footer class="footer">
        <!-- <div class="content has-text-centered">
            <h2 class="title has-text-centered">Follow Us</h2>
            <div class="social-icons has-text-centered">
            <a href="https://twitter.com/your_handle" target="_blank" rel="noopener">
                <font-awesome-icon :icon="['fab', 'twitter']" />
            </a>
            <a href="https://facebook.com/your_page" target="_blank" rel="noopener">
                <font-awesome-icon :icon="['fab', 'facebook']" />
            </a>
            <a href="https://instagram.com/your_handle" target="_blank" rel="noopener">
                <font-awesome-icon :icon="['fab', 'instagram']" />
            </a>
            <router-link to="/game-studio">About Studio</router-link>
            </div>
        </div> -->

        <div class="content has-text-centered">
            <p>
            Built with
            <a href="https://vuejs.org" target="_blank" rel="noopener">Vue.js</a> and
            <a href="https://firebase.google.com" target="_blank" rel="noopener">Firebase</a>
            </p>
        </div>
        </footer>
    </div>
    </template>

    <script>
    import { provider, signOutUser, authStateChanged, auth, signInWithPopup } from '@/firebase';
    const isAuthenticated = false

    export default {
        name: 'HomePage',
        components: {

        },
        created() {
        authStateChanged((user) => {
            console.log(`You are ${user ? 'logged in' : 'logged out'}`);
            this.user = user;
        });
        },
        computed: {
        username() {
            return this.$route.params.username
        },
        },
        methods: {
        async signInWithGoogle() {
            try {
            const result = await signInWithPopup(auth, provider);
            // const result2 = await signInWithRedirect(provider);
            console.log(result)
            } catch (error) {
            console.log('Error during Google sign-in:', error);
            }
        },
        async signOut () {
            try {
            await signOutUser();
            this.user = null;
            } catch (error) {
            console.error('Error during sign-out:', error);
            }
        },
        goToDashboard() {
            if (isAuthenticated) {
            this.$router.push('/')
            } else {
            this.$router.push('/login')
            }
        },
        },
        data() {
        return {
            days: 0,
            hours: 0,
            minutes: 0,
            seconds: 0,

            user: null,
        };
        },
        mounted() {
        // auth.onAuthStateChanged((user) => {
        //   if (user) {
        //     this.user = user;
        //   }
        // });
        const releaseDate = new Date('2023-12-01T00:00:00').getTime();
        const updateCountdown = () => {
            const now = new Date().getTime();
            const timeLeft = releaseDate - now;

            this.days = Math.floor(timeLeft / (1000 * 60 * 60 * 24));
            this.hours = Math.floor((timeLeft % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
            this.minutes = Math.floor((timeLeft % (1000 * 60 * 60)) / (1000 * 60));
            this.seconds = Math.floor((timeLeft % (1000 * 60)) / 1000);
        };

        updateCountdown();
        setInterval(updateCountdown, 1000);
        },
    };
    </script>

    <style>
    @import 'bulma/css/bulma.css';

    .gma-homepage {
        background-color: #fbfff7;
        min-height: 100vh;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    .hero {
        background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('@/assets/background-image.jpg');
        background-size: cover;
        background-position: center;
    }

    .countdown-timer {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        margin: 20px;
    }

    .video-container {
        position: relative;
        width: 100%;
        padding-bottom: 56.25%;
        height: 0;
        overflow: hidden;
    }

    .video-container iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    .newsletter-form {
        display: flex;
        justify-content: center;
        max-width: 400px;
        margin: 0 auto;
    }

    .field.has-addons.is-centered {
        justify-content: center;
        width: 100%;
    }

    .social-icons {
        font-size: 24px;
        display: flex;
        justify-content: center;
    }

    .social-icons a {
        color: inherit;
        margin: 0 10px;
        text-decoration: none;
    }

    .social-icons a:hover {
        color: #333;
    }

    </style>
