import { createApp } from 'vue'
import App from './App.vue'
import { VueFire, VueFireFirestoreOptionsAPI } from 'vuefire'
import { createRouter, createWebHistory } from 'vue-router'

import { FontAwesomeIcon } from './font-awesome';
import { firebaseApp, auth, authStateChanged } from './firebase';

import HomePage from '@/components/HomePage.vue';
import GameStudio from '@/components/GameStudio.vue';

const app = createApp(App)

const routes = [
    { path: '/', name: 'HomePage', component: HomePage },
    { path: '/game-studio', name: 'GameStudio', component: GameStudio },
  ];

const router = createRouter({
    history: createWebHistory(),
    routes,
})

app.provide('$firebase', firebaseApp)
app.provide('$auth', auth)
app.component('font-awesome-icon', FontAwesomeIcon);

app.use(VueFire, {
    app: firebaseApp,
    router,
    modules: [
        VueFireFirestoreOptionsAPI(),
    ],
})

app.use(router)

authStateChanged(user => {
    console.log(`You are ${user ? 'logged in' : 'logged out'}`);
});

app.mount('#app')
