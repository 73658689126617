// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth, GoogleAuthProvider, signInWithPopup, signInWithEmailAndPassword, onAuthStateChanged } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';
import { getAnalytics } from "firebase/analytics";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBl7MCodcpnwtzryiIfFZ4xhAVYnCVLfj0",
  authDomain: "fir-vuewebapp.firebaseapp.com",
  projectId: "fir-vuewebapp",
  storageBucket: "fir-vuewebapp.appspot.com",
  messagingSenderId: "437277407852",
  appId: "1:437277407852:web:abd0bee92e8ec226347649",
  measurementId: "G-DWXLDGQ044"
};

// Initialize Firebase
const firebaseApp = initializeApp(firebaseConfig);

const db = getFirestore();
const storage = getStorage();
const analytics = getAnalytics(firebaseApp);
const auth = getAuth(firebaseApp);
const provider = new GoogleAuthProvider();

const authStateChanged = (callback) => {
  onAuthStateChanged(auth, callback);
};

const signOutUser = async () => {
  try {
    await auth.signOut();
  } catch (error) {
    console.error('Error during sign-out:', error);
  }
};

export { db, storage, analytics, firebaseApp, auth, authStateChanged, signInWithPopup, signInWithEmailAndPassword, signOutUser, provider };